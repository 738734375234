import React, { useState } from 'react';
import { useFormik } from 'formik';
import {
  Card,
  Stack,
  Button,
  TextField,
  Container,
  Typography,
  Snackbar,
  Alert
} from '@mui/material';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import * as Yup from 'yup';
import axios from '../axios/axios';

export default function Prediction() {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [fertilizerResult, setFertilizerResult] = useState('');
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    Nitrogen: Yup.number()
      .required('Nitrogen is required')
      .positive('Nitrogen must be a positive number'),
    Phosporus: Yup.number()
      .required('Phosporus is required')
      .positive('Phosporus must be a positive number'),
    Potassium: Yup.number()
      .required('Potassium is required')
      .positive('Potassium must be a positive number')
  });

  const formik = useFormik({
    initialValues: {
      Nitrogen: '',
      Phosporus: '',
      Potassium: ''
    },
    validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      axios
        .post('/fertilizer_recommendation', values)
        .then((response) => {
          setLoading(false);
          setFertilizerResult(response.data.fertilizer_result);
          setOpenSnackbar(true);
        })
        .catch((error) => {
          setLoading(false);
          setFertilizerResult('');
          setOpenSnackbar(true);
          console.error(error);
        });
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Container>
      <Card>
        <Stack spacing={2} p={4}>
          <Typography variant="h4" gutterBottom>
            Fertilizer Recommendation
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              label="Nitrogen"
              id="Nitrogen"
              variant="outlined"
              fullWidth
              sx={{ mb: 2 }}
              onChange={formik.handleChange}
              value={formik.values.Nitrogen}
              error={Boolean(touched.Nitrogen && errors.Nitrogen)}
              helperText={touched.Nitrogen && errors.Nitrogen}
              required
            />
            <TextField
              label="Phosporus"
              id="Phosporus"
              variant="outlined"
              fullWidth
              sx={{ mb: 2 }}
              onChange={formik.handleChange}
              value={formik.values.Phosporus}
              error={Boolean(touched.Phosporus && errors.Phosporus)}
              helperText={touched.Phosporus && errors.Phosporus}
              required
            />
            <TextField
              label="Potassium"
              id="Potassium"
              variant="outlined"
              fullWidth
              sx={{ mb: 2 }}
              onChange={formik.handleChange}
              value={formik.values.Potassium}
              error={Boolean(touched.Potassium && errors.Potassium)}
              helperText={touched.Potassium && errors.Potassium}
              required
            />
            <Button
              type="submit"
              variant="contained"
              disabled={loading}
              startIcon={<Icon icon={plusFill} />}
            >
              Predict
            </Button>
          </form>
        </Stack>
      </Card>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Alert onClose={handleCloseSnackbar} severity="success">
          Fertilizer Recommendation: {fertilizerResult}
        </Alert>
      </Snackbar>
    </Container>
  );
}
